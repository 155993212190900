.default-section {
    max-width: 100%;
    padding: 15px !important;
    margin-bottom: 20px;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logout-confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: white;
    /* border: 2px solid #000; */
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.logout-action {
    padding: 20px;
    text-align: center;
}

.confirm-button {
    background-color: #1C5387F2 !important;
    margin-right: 10px !important;
}

.cancel-button {
    background-color: rgba(157, 155, 155, 0.8) !important;
    margin-left: 10px !important;
}

@media (max-width: 472px) {
    .default-section{
        padding-bottom: 0px !important;
    }
}