.detail-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 0.5rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #edf0f7;
    border-radius: 0.5rem;
}

.body {
    padding: 20px 30px 0 30px;
    font-size: 13px !important;
}

.ok-box {
    padding: 20px 50px 20px 20px;
    text-align: end;
}

.ok-button {
    background-color: #285C8D;
}

.ok-button:hover {
    background-color: #2C74B8 !important;
}

.borderedRow {
    border: 1px solid #ccc
}

.tableCellWithBorder {
    border-right: 1px solid #ccc;
    min-width: 100px;
    background-color: #2A75EA1C;
}

.detail_title {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    background-color: #edf0f7;
    border-radius: 0.5rem;
    font-size: 13px !important;
}

.detail_value {
    font-size: 13px !important;
    /* text-align: center; */
}

.receiver_container {
    max-height: 170px;
    overflow-y: auto;
}


@media (max-width: 700px) {
    .receiver_table tr {
      display: block;
      margin-bottom: 10px;
    }
  
    .receiver_table td {
      display: block;
    }
  }