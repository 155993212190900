

.title-text {
    color: #4385EA !important;
}

.login-form-component {
    /* padding-top: 2rem !important; */
    margin: 2rem auto !important;
    border-radius: 1.5rem !important;
}

.login-field-component {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.login-field-button {
    background-color: #4385EA !important;
    color: white !important;
}

.captcha-component {
    display: flex;
    padding-top: 2rem;
    justify-content: center;
    /* display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)); */
}

.css-tfm83w-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: white;
}

@media (max-width: 576px) {
    /* big container */
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }

    /* 6D text */
    .title-component {
        width: 100%;
        text-align: center;
    }

    /* 6D text */
    .title-text {
        font-weight: bold !important;
        font-size: 26px !important;
    }


    /* form component */
    .login-form-component {
        width: 70%;
    }


    /* login field */
    .login-field-component {
        grid-column: auto !important;
        row-gap: 25px;
        /* padding-bottom: 10px; */
    }


    /* login button */
    .login-button-component {
        margin-top: 40px !important;
    }

    /* login button */
    .login-field-button {
        width: 100%;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 14px !important;
    }

}


@media (min-width: 577px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }

    .login-field-component {
        grid-gap: 30px;
    }

    .login-button-component {
        margin-top: 40px !important;
    }

    .login-field-button {
        width: 100%;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 15px !important;
    }
}

